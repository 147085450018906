import CIcon from '@coreui/icons-react';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { AppSidebarNav } from './AppSidebarNav';
import 'simplebar/dist/simplebar.min.css';
import { logoNegative } from '../../assets/brand/logo-negative';
import { sygnet } from '../../assets/brand/sygnet';
import icon from '../../assets/brand/icon.jpg';
import { adminNavigation, userNavigation } from '../../constants';
import { useAuth } from '../../context/AuthContext';

// sidebar nav config

const AppSidebar = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const userNav = userNavigation.filter((obj) => !(obj.emailVerified && !user?.emailVerified));
  return (
    <CSidebar
      position='fixed'
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible });
      }}
    >
      {/* <a class='header-brand mx-auto d-md-none' to='/'>
        <title>coreui react pro</title>
      </a> */}
      <CSidebarBrand className='d-none d-md-flex' to='/'>
        {/* <CIcon className='sidebar-brand-full' icon={logoNegative} height={35} /> */}
        {/* <CIcon className='sidebar-brand-narrow' icon={sygnet} height={35} /> */}
        <img width={50} height={50} alt='logo' className='sidebar-brand-full' src={icon} />
        <h4 style={{ marginLeft: 5 }}>Moojii Dental</h4>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={user?.isAdmin ? adminNavigation : userNav} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className='d-none d-lg-flex'
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
