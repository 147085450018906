import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Header({ path }) {
  const { user, handleLogout } = useAuth();
  // console.log('HEADER USER', user);
  return (
    // <div className='sub_page'>
    <div className='sub_page'>
      <div className='hero_area' style={{ backgroundColor: path === '/' ? 'transparent' : '' }}>
        {/* <!-- header section strats --> */}
        <header className='header_section'>
          <div className='container'>
            <nav className='navbar navbar-expand-lg custom_nav-container '>
              <Link className='navbar-brand' to='/'>
                <span>Эмнэлэг нэр</span>
              </Link>
              <button
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className=''> </span>
              </button>

              <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                <ul className='navbar-nav'>
                  {/* <li className='nav-item active'>
                    <Link className='nav-link' to='/'>
                      Нүүр
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/about'>
                      Бидний тухай
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/department'>
                      Departments
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/doctors'>
                      Эмч ажилчид
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/contact'>
                      Холбоо барих
                    </Link>
                  </li> */}
                  {user ? (
                    <>
                      <li className='nav-item'>
                        <Link className='nav-link' to='/dashboard'>
                          Dashboard
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <a className='nav-link' href='#' onClick={() => handleLogout()}>
                          Гарах
                        </a>
                      </li>
                    </>
                  ) : (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/login'>
                        Нэвтрэх
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        {/* <!-- end header section --> */}
      </div>
    </div>
  );
}

export default Header;
